import api from "../utils/api";

const clientes = {
  namespaced: true,
  state: {
    listaClientesSelect: [],
    clienteSeleccionado: null,
  },
  getters: {
    listaClientesSelect: (state) => state.listaClientesSelect,
    clienteSeleccionado: (state) => state.clienteSeleccionado,
  },
  mutations: {
    SET_LISTAR_CLIENTES_SELECT(state, newValue) {
      state.listaClientesSelect = newValue;
    },
    SET_CLIENTE_SELECCIONADO(state, newValue) {
      state.clienteSeleccionado = newValue;
    },
  },
  actions: {
    async listarClientesSelect({ commit }) {
      await api("GET", "usuarios/consultar-usuarios/cliente").then((result) => {
        commit("SET_LISTAR_CLIENTES_SELECT", [
          { value: null, text: "Seleccione un cliente", name: "Seleccione un cliente" },
          ...result.usuarios.map((res) => {
            return {
              value: res.usuario_id,
              text: res.razon_social,
              name: res.nombre,
            };
          }),
        ]);
      });
    },
    async listarClientesTodosSelect({ commit }) {
      await api("GET", "usuarios/consultar-usuarios/cliente", {}, { todos: true }).then((result) => {
        commit("SET_LISTAR_CLIENTES_SELECT", [
          { value: null, text: "Seleccione un cliente", name: "Seleccione un cliente" },
          ...result.usuarios.map((res) => {
            return {
              value: res.usuario_id,
              text: res.razon_social,
              name: res.nombre,
            };
          }),
        ]);
      });
    },
    selectCliente({ commit, getters }) {
      commit("SET_CLIENTE_SELECCIONADO", getters.clienteSeleccionado);
    },
  },
};

export default clientes;
