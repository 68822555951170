<template>
  <b-container fluid id="Ofertas">
    <b-tabs content-class="mt-3">
      <b-tab title="Agregar Ofertas" active>
        <AgregarOfertas />
      </b-tab>
      <b-tab title="Remover Ofertas" @click="$refs.remover.get()">
        <RemoverOfertas ref="remover" />
      </b-tab>
      <b-tab title="Remover Ofetas De Marca">
        <RemoverMarca />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import AgregarOfertas from "./Agregar.vue";
import RemoverOfertas from './Remover.vue';
import RemoverMarca from "./RemoverMarca.vue";

export default {
  name: "Ofertas",
  components: { AgregarOfertas, RemoverOfertas, RemoverMarca },
};
</script>
<style lang="scss" src="./ofertas.scss" />