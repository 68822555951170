<template>
  <div id="BaseSelectCliente">
    <b-form-select v-model="selected" :options="listaClientesSelect" @change="emitirCliente"></b-form-select>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "BaseSelectCliente",
  data() {
    return {
      selected: null,
      usuarios: [],
    };
  },
  computed: {
    ...mapState("clientes", ["listaClientesSelect"]),
  },
  async mounted() {
    await this.listarClientesTodosSelect();
  },
  methods: {
    ...mapActions("clientes", ["listarClientesTodosSelect"]),
    emitirCliente() {
      this.$emit("select-cliente", this.selected);
    },
    limpiarSeleccion() {
      this.selected = null;
    },
  },
  emits: ['select-cliente'],
};
</script>
<style lang="scss">
#BaseSelectCliente {
  margin: 10px;
}
</style>