<template>
  <div id="BaseButton">
    <b-button :class="buttonType" :disabled="disabled" @click="cta()">{{
      title
    }}</b-button>
  </div>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    title: {
      type: String,
      default: "BaseButton",
    },
    buttonType: {
      type: String,
      default: "azul",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    cta() {
      this.$emit("onCta");
    },
  },
  computed: {},
};
</script>
<style lang="scss">
#BaseButton {
  margin: 10px;
}
.azul {
  &:hover {
    background-color: #293f59 !important;
    border-color: #355070 !important;
  }
  border-color: #355070 !important;
  background-color: #355070 !important;
}
.rojo {
  &:hover {
    background-color: rgb(167, 83, 70) !important;
    border-color: rgb(167, 83, 70) !important;
  }
  background-color: rgb(165, 89, 78) !important;
  border-color: rgb(165, 89, 78) !important;
}
.verde {
  &:hover {
    background-color: rgb(50, 109, 53) !important;
    border-color: rgb(50, 109, 53) !important;
  }
  background-color: rgb(64, 141, 68) !important;
  border-color: rgb(64, 141, 68) !important;
}
</style>