<template>
  <b-container fluid>
    <section id="busqueda">
      <article class="w-50">
        <label for="proveedores">Proveedores</label>
        <Multiselect multiple id="proveedores" :options="proveedores" v-model="proveedorSelected"
          placeholder="Seleccione un proveedor." searchable allow-empty track-by="value" label="text" />
      </article>
    </section>

    <section class="d-flex">
      <article class="mr-4">
        <b-button @click="getMarca" variant="primary" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          Buscar ofertas de Marca
        </b-button>
      </article>

      <article class="mr-4">
        <b-button @click="eliminarOferta" variant="primary" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          Eliminar seleccionadas
        </b-button>
      </article>
    </section>

    <b-overlay :show="loading">
      <b-table-simple bordered small hover style="font-size: 14px;" sticky-header="400px">
        <b-thead>
          <b-tr>
            <b-th class="text-center">
              <b-form-checkbox v-model="seleccionarTodas" name="checkbox-1" value="true" unchecked-value="" />
            </b-th>
            <b-th class="text-center">Proveedor</b-th>
            <b-th class="text-center">Marca</b-th>
            <b-th class="text-center" style="text-wrap: nowrap;">Precio 1</b-th>
            <b-th class="text-center" style="text-wrap: nowrap;">Precio 2</b-th>
            <b-th class="text-center" style="text-wrap: nowrap;">Precio 3</b-th>
            <b-th class="text-center" style="text-wrap: nowrap;">Precio 4</b-th>
            <b-th class="text-center" style="text-wrap: nowrap;">Precio 5</b-th>
            <b-th class="text-center">Porcentaje</b-th>
            <b-th class="text-center">Fecha Fin</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(oferta, index) of ofertasDeMarca" :key="index">
            <b-td class="text-center">
              <b-form-checkbox v-model="oferta.seleccionada" name="checkbox-1" value="true" unchecked-value="" />
            </b-td>
            <b-td class="text-center">{{ oferta.Proveedor.nombre }}</b-td>
            <b-td class="text-center">{{ oferta.Marca.nombre }}</b-td>
            <b-td class="text-right">${{ oferta.Precio_1 }}</b-td>
            <b-td class="text-right">${{ oferta.Precio_2 }}</b-td>
            <b-td class="text-right">${{ oferta.Precio_3 }}</b-td>
            <b-td class="text-right">${{ oferta.Precio_4 }}</b-td>
            <b-td class="text-right">${{ oferta.Precio_5 }}</b-td>
            <b-td class="text-center">{{ oferta.Porcentaje }}%</b-td>
            <b-td class="text-center">{{ oferta.FechaFin ? parseDate(oferta.FechaFin) : '' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-overlay>
    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
  </b-container>
</template>

<script>
import Multiselect from 'vue-multiselect';
import moment from 'moment-timezone';
import { axiosInstance } from '../../../utils/axiosInstance';
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      ofertasDeMarca: [],
      loading: false,
      seleccionarTodas: '',
      proveedores: [],
      proveedorSelected: [],
      marcas: [{ value: null, text: 'Seleccione una marca' }],
      marcaSelected: null,
      codigo: '',
      currentPage: 1,
      rows: 0,
      perPage: 500,
      ofertasMarca: false,
    }
  },
  mounted() {
    this.getMarca()
    axiosInstance.get('proveedores', {
      headers: {
        authorization: localStorage.getItem('token')
      }
    })
      .then(proveedores => {
        proveedores.data.forEach(proveedor => {
          this.proveedores.push({ value: proveedor.proveedor_id, text: proveedor.nombre })
        })
      })
      .catch(() => {
        this.makeToast('Proveedores', 'Hubo un error al obtener los proveedores', 'danger', 3000);
      });

    axiosInstance.get('marcas', {
      headers: {
        authorization: localStorage.getItem('token')
      }
    })
      .then(marcas => {
        marcas.data.forEach(marca => {
          this.marcas.push({ value: marca.marca_id, text: marca.nombre })
        })
      })
      .catch(() => {
        this.makeToast('Marcas', 'Hubo un error al obtener las marcas', 'danger', 3000);
      })
  },
  methods: {
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    getMarca() {
      this.loading = true;
      axiosInstance.get('ofertas/marcas', {
        params: {
          ProveedorID: this.proveedorSelected.map(proveedor => proveedor.value),
        }
      })
        .then(result => {
          this.ofertasDeMarca = result.data.map(oferta => ({ ...oferta, seleccionada: '' }));
          this.ofertasMarca = true
        })
        .catch((error) => this.makeToast('Obtener ofertas', error.response.data.msg || 'Hubo un error al obtener las ofertas', 'danger', 3000))
        .finally(() => this.loading = false);
    },
    eliminarOferta() {
      const eliminar = this.ofertasDeMarca.filter(oferta => oferta.seleccionada).map(oferta => oferta.OfertaID);

      if (!eliminar.length) {
        this.makeToast('Eliminar ofertas', 'Seleccione al menos una oferta.', 'warning', 3000);
        return;
      }

      this.loading = true;

      axiosInstance.delete('ofertas/marca', {
        data: { OfertaID: eliminar },
      }).then(() => {
        this.makeToast('Eliminar ofertas', 'Ofertas eliminadas', 'success', 3000);
        this.getMarca();
      }).catch((error) => this.makeToast('Eliminar ofertas', error.response.data.msg || 'Hubo un error al eliminar las ofertas.', 'danger', 3000))
        .finally(() => this.loading = false)
    },
    limpiar() {
      this.marcaSelected = null;
      this.proveedorSelected = [];
    },
    parseDate(date) {
      return moment(date.split('T')[0]).format('DD/MM/YYYY')
    }
  },
  watch: {
    seleccionarTodas(newValue) {
      if (newValue) {
        this.ofertasDeMarca.forEach((oferta, index, array) => {
          array[index].seleccionada = 'true';
        })
      }
      else {
        this.ofertasDeMarca.forEach((oferta, index, array) => {
          array[index].seleccionada = '';
        })
      }
    },
    currentPage() {
      this.get();
    }
  }
}
</script>